import React, { useContext, useEffect } from "react";
import { Context } from "redux/Store";
import { getUrlFromSlug } from "libs/services/utils";
import Layout from "layout";
import { Row, Col } from "react-grid-system";
import Seo from "common/seo";
import ProductDetails from "common/product-details";
import AccordionContent from "common/accordion";
import { TypeA, TypeC } from "common/callouts";
import useGoToAnchor from "libs/hooks/useGoToAnchor";

import frontImage from "images/baby/general-care/aquaphor_baby_ointment_front.webp";
import backImage from "images/baby/general-care/aquaphor_baby_ointment_back.webp";
import frontZoomImage from "images/baby/general-care/aquaphor_baby_ointment_front_zoom.webp";
import backZoomImage from "images/baby/general-care/aquaphor_baby_ointment_back_zoom.webp";

import img2 from "images/baby/general-care/aquaphor-baby-ointment-lanolin.webp";
import img3 from "images/baby/general-care/aquaphor-baby-ointment-barrier.webp";
import img4 from "images/baby/general-care/aquaphor-baby-ointment-treatment-areas.webp";
import img5 from "images/baby/general-care/ointment-resource-card.webp";
import img6 from "images/baby/general-care/Diaper-Rash-Callout_Footer.webp";
import img7 from "images/baby/general-care/Baby-Resources-Callout_Footer.webp";

import "./aquaphorbabyhealingointment.scss";

function AquaphorOintmentPage({ pageContext }) {
  const { state, dispatch } = useContext(Context);

  useGoToAnchor();

  return (
    <Layout
      pageClass="page-baby-generalcare page-level-3"
      breadcrumbData={pageContext.breadcrumb}
    >
      <Seo
        pageTitle="Aquaphor Baby Healing Ointment"
        pageDescription="Learn about Aquaphor Baby Healing Ointment"
      />
      <div className="inner-body-content">
        <div className="top-content-level-3">
          <section className="inner-centered-container">
            <ProductDetails
              categoryBgColor="#5daee1"
              categoryName="GENERAL CARE"
              categoryFontColor="#ffffff"
              productName="Aquaphor<sup>®</sup> Baby Healing Ointment"
              productSlider={[frontImage, backImage]}
              zoomImages={[frontZoomImage, backZoomImage]}
            >
              <div className="functions">
                <p className="font-semi-bold pictonblue-text sub-title">
                  One essential solution for a variety of baby skin care needs
                </p>
                <p>
                  <span className="font-semi-bold">
                    Aquaphor Baby Healing Ointment
                  </span>{" "}
                  is a multipurpose solution, uniquely formulated to provide the
                  care babies' tender and delicate skin needs.
                </p>
                <ul className="picton-bullets">
                  <li>
                    <span>
                      Provides the barrier protection needed to help prevent
                      diaper rash when used with every diaper change
                    </span>
                  </li>
                  <li>
                    <span className="font-semi-bold">
                      Clinically proven to restore smooth, healthy skin
                    </span>
                  </li>
                  <li>
                    <span>
                      Uniquely formulated with 41% petrolatum (skin protectant)
                      to allow oxygen to flow and help heal the skin
                    </span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients">
                <p className="font-semi-bold pictonblue-text sub-title">
                  Active ingredient
                </p>
                <p className="font-semi-bold">
                  41% petrolatum (skin protectant)
                </p>
                <ul className="picton-bullets">
                  <li>
                    <span>Protects exposed skin surfaces</span>
                  </li>
                  <li>
                    <span>Locks in moisture</span>
                  </li>
                </ul>
              </div>
              <div className="key-ingredients no-brd">
                <p className="font-semi-bold pictonblue-text sub-title">
                  Key ingredients
                </p>
                <Row>
                  <Col sm={12} md={6}>
                    <p className="font-semi-bold">Panthenol</p>
                    <ul className="picton-bullets">
                      <li>
                        <span>Softens and conditions skin</span>
                      </li>
                      <li>
                        <span>Binds water to support moisture</span>
                      </li>
                    </ul>
                    <p className="font-semi-bold">Lanolin alcohol</p>
                    <ul className="picton-bullets">
                      <li>
                        <span>Supports barrier repair</span>
                      </li>
                      <li>
                        <span>Defends against moisture loss</span>
                      </li>
                    </ul>
                  </Col>
                  <Col sm={12} md={6}>
                    <p className="font-semi-bold">Glycerin</p>
                    <ul className="picton-bullets">
                      <li>
                        <span>Binds water</span>
                      </li>
                      <li>
                        <span>Replenishes moisture reservoir</span>
                      </li>
                    </ul>
                    <p className="font-semi-bold">Bisabolol</p>
                    <ul className="picton-bullets">
                      <li>
                        <span>Soothes dry, irritated skin</span>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </ProductDetails>
            <Row>
              <Col xs={12}>
                <div className="features">
                  <p className="font-semi-bold pictonblue-text sub-title">
                    Formulated for dry, cracked skin
                  </p>
                  <ul className="checkmarks pictonblue">
                    <li>
                      <span>HYPOALLERGENIC</span>
                    </li>
                    <li>
                      <span>NON-COMEDOGENIC</span>
                    </li>
                    <li>
                      <span>PRESERVATIVE-FREE</span>
                    </li>
                    <li>
                      <span>FRAGRANCE-FREE</span>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </section>
        </div>
        <div className="gradient-bg">
          <section className="inner-centered-container">
            <AccordionContent
              accordionHeading="LANOLIN PURIFICATION"
              productThemeColor="baby-picton-blue"
              id="lanolinpurification"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img2}
                    className="accordion-chart"
                    alt="Lanolin Purification"
                  />
                </Col>
                <Col sm={12} md={4}></Col>
              </Row>
            </AccordionContent>
            <AccordionContent
              accordionHeading="BREATHABLE BARRIER"
              productThemeColor="baby-picton-blue"
              id="breathablebarrier"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img3}
                    className="accordion-chart"
                    alt="Wound healing with breathable barrier"
                  />
                </Col>
                <Col sm={12} md={4}>
                  <div className="bordered-content left-align">
                    <p className="text-left font-semi-bold small-p">
                      Studies demonstrate that <nobr>semi-occlusive</nobr>{" "}
                      dressings improve wound healing vs occlusive dressings
                    </p>
                    <p className="font-18 font-semi-bold font-slate-grey">
                      How a semi-occlusive formulation helps:
                    </p>
                    <ul className="picton-bullets">
                      <li>
                        <span>
                          <span className="font-semi-bold">
                            Enables transepidermal water loss
                          </span>{" "}
                          to signal the body to{" "}
                          <span className="font-semi-bold">begin repair</span>{" "}
                          of the disrupted barrier
                        </span>
                      </li>
                      <li>
                        <span>
                          <span className="font-semi-bold">
                            Promotes ceramide synthesis,
                          </span>{" "}
                          which is necessary for barrier recovery
                        </span>
                      </li>
                      <li>
                        <span>
                          <span className="font-semi-bold">
                            Allows oxygen to enter;
                          </span>{" "}
                          vital for cells and tissues
                        </span>
                      </li>
                      <li>
                        <span>
                          <span className="font-semi-bold">
                            Regulates moisture
                          </span>{" "}
                          to prevent tissue maceration
                        </span>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </AccordionContent>
            <AccordionContent
              accordionHeading="TREATMENT AREAS"
              productThemeColor="baby-picton-blue"
              id="treatmentareas"
            >
              <Row align="center" justify="center" direction="row">
                <Col sm={12} md={8}>
                  <img
                    src={img4}
                    className="accordion-chart"
                    alt="Applicable Treatment Areas"
                  />
                </Col>
                <Col sm={12} md={4}></Col>
              </Row>
            </AccordionContent>
          </section>
        </div>
        <section className="callouts-container gradient-bg section-support-resource blue">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={8} order={{ xs: 2, md: 1 }} className="">
                <TypeC
                  buttonText="DOWNLOAD HERE"
                  buttonUrl={getUrlFromSlug(
                    "aquaphor-baby-healing-ointment",
                    state?.sanityData?.allResources
                  )}
                  className="center-v"
                  isExternalLink={true}
                  targetOpen="_blank"
                >
                  <p className="reg-text-big">
                    Support your recommendation <br className="show-desktop" />{" "}
                    with this resource
                  </p>
                  <p className="reg-text">
                    Review how Aquaphor provides a full range of care to help
                    prevent and treat diaper rash.
                  </p>
                </TypeC>
              </Col>
              <Col sm={12} md={4} order={{ xs: 1, md: 2 }} className="">
                <img
                  src={img5}
                  alt="Resource for Diaper Rash Prevention"
                  className="cout-product-img"
                />
              </Col>
            </Row>
          </div>
        </section>
        <section className="callouts-container gradient-bg blue-grad">
          <div className="inner-centered-container">
            <Row>
              <Col sm={12} md={6}>
                <TypeA
                  imgRef={img6}
                  imgAlt="DIAPER RASH RELIEF"
                  caption="Recommended for relief <br class='show-desktop' /> of mild to severe diaper rash"
                  buttonText="DIAPER RASH RELIEF"
                  buttonUrl="/baby/diaperrash"
                  imgStyles={{ height: 158 }}
                />
              </Col>
              <Col sm={12} md={6}>
                <TypeA
                  imgRef={img7}
                  imgAlt="Resource for Baby Care"
                  caption="Find resources to support your<br class='show-desktop' /> baby care recommendations"
                  buttonText="BROWSE RESOURCES"
                  buttonUrl="/resources"
                  imgStyles={{ height: 165 }}
                />
              </Col>
            </Row>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default AquaphorOintmentPage;
